body{
  &.edit_mode{
    a[href*='edit_header_code']{
      display: none;
    }
  }
  &#edit-head-code {
    a[href*='edit_header_code']{
      display: block;
    }
  }
}

.edit_mode {

        .snFooterContainer {
                position: relative;

                &:before {
                        content: '';
                        position: absolute;
                        display: block;
                        background: rgba(136,9,0,.85);
                        height: 100%;
                        width: 100%;
                        top: 0;
                        left: 0;
                        z-index: 1;
                        opacity: 0;
                        transition: opacity $transition-standard;
                }

                &:after {
                        content: 'Please Edit your Footer Information on your Assets page. Thank You!';
                        position: absolute;
                        display: block;
                        width: 100%;
                        text-align: center;
                        color: white;
                        text-transform: uppercase;
                        left: 0;
                        top: calc(50% - 25px);
                        font-size: 24px;
                        z-index: 2;
                        opacity: 0;
                        transition: opacity $transition-standard;
                }

                &:hover {

                        &:before,
                        &:after {
                                opacity: 1;
                        }

                }

        }

}


.home {
  .theme-sub-nav {
    display: none !important;
  }


}

.has-sub-nav .home #topNav.topNav.layout-3 + #topNavPlaceholder {
  height: calc((  40 +   47 + 120 +   40  )*1px - 40px);
}

.has-sub-nav .home #topNav.topNav.nav-fixed + #topNavPlaceholder {
  height: calc((  40 + 30 + 120  )*1px - 65px);
}


html.nav-fixed {
  padding-bottom: 0;
}
html.nav-fixed .site-background {
  bottom: 0;
}
a {
  color: #00214D;
}
.pageOptions, .extendedOptions {
  padding-top: 5px;
  padding-bottom: 5px;
}
.textBlockElement h3,
.eventAggregatorElement .aggHeader,
.aggHeader {
  max-width: initial;
  width: 100%;
  border: none;
  box-shadow: none;
  background: transparent;
  color: #00214D;
  padding-left:0;
}
// news aggregators
.newsAggregatorElement .item {
  border-top: none;
  padding-top: 15px;
  padding-bottom: 0;
}
.newsAggregatorElement .item .newsItemElement,
.newsAggregatorElement .details {
  border-bottom: 1px solid lightgray;
  padding-bottom: 15px;
}
.yieldPageContent .pageElement .item.extendedOptions {
  padding-top:5px;
  padding-bottom:5px;
}
.newsAggregatorElement .articleHasImage .newsItemHeader > a {
  position: relative;
}
.newsAggregatorElement .item a > img {
  max-width: 120px;
  max-height: 100px;
}
.newsAggregatorElement .expanded .newsItemHeader h2,
.newsAggregatorElement .details {
  padding-top: 2%;
}
.newsAggregatorElement .expanded .newsItemHeader h2,
.newsAggregatorElement .item .details h4, .item > ul h4,
.eventAggregatorElement .summary {
  font-size: 18px;
  font-weight: normal;
}
.newsAggregatorElement .newsAuthor, .newsAggregatorElement .newsDate {
  font-size: 13px;
  padding: 5px 0;
}
.expanded .newsItemElement h4,
.newsAggregatorElement .item .teaser {
  font-size: 16px;
  text-transform: initial;
}
.newsAggregatorElement .articleHasImage > h4,
.newsAggregatorElement .articleHasImage .newsText,
.newsAggregatorElement .articleHasImage .commentsAndMore,
.newsAggregatorElement .articleHasImage .newsItemHeader {
  padding-left:0;
}
.eventAggregatorElement .vevent ~ .vevent {
  border:none;
}
.eventAggregatorElement .dateImage {
  background: white;
  padding: 0;
}
.eventAggregatorElement .dateImage:after {
  content: none;
}
.eventAggregatorElement .dateImage .month {
  background: #00214D;
  color: white;
  font-size: 20px;
  padding: 10px 0;
}
.eventAggregatorElement .dateImage .date {
  color: #cf142b;
  padding: 5px 0;
  font-size: 48px;
}
// read more button
.siteContainer [class*="button-"] {
  border-bottom: none;
  padding: 5px 25px;
  text-align: left;
  font-weight: normal;
  position:relative;
  color: #fff !important;
}
.siteContainer [class*="button-"]:after {
  content:'';
  display: inline-block;
  position: relative;
  margin-left: 5px;
  border-left: 5px solid #00214D;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  transition: transform 200ms ease-out;
}
.linkElement .goToLink:after,
.linkElement .emailLink:after {
  content: '';
  display: inline-block;
  position: relative;
  margin-left: 5px;
  border-bottom: 5px solid transparent;
  border-top: 5px solid transparent;
  border-left: 5px solid #00214D;
}
.linkElement h4 a {
  text-align: center;
  font-weight: normal;
  box-shadow: none;
  background-color: #cf142b
}
/*custom banner*/
html.has-main-nav:not(.has-sub-nav) #siteHeader {
  margin-top: 0;
}
#siteHeader.customBannerStyle {
  h1 {
    font-weight: bold;
    font-size: 40px;
    color: white;
    display: inline-block;
    position: relative;
    padding-bottom: 10px;
    &:after {
      content: '';
      position: absolute;
      width: 50%;
      width: 50%;
      border-bottom: 2px solid white;
      left: 0;
      bottom: 0;
      margin: 0 25%;
    }
  }
  .site-banner-wrapper {
    max-height: initial;
    height: 200px;
    min-height: 200px;
    max-width: 100%;
    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background: #00214D;
      mix-blend-mode: color;
    }
  }
  .home & {
    display: none;
  }
}
